import store from '../store'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.locale('ja')

const isset = (value) => {
  return typeof value != 'undefined' && value != null
}
const setValue = (value) => {
  return !isset(value) ? '-' : value
}
const secondToTime = (seconds) => {
  return dayjs(new Date(seconds * 1000)).format('YYYY/M/D')
}
const convertMasterName = (id, name) => {
  return store.state.masterModule.master[name][id].name
}
export default {
  computed: {
    formatAccount: () => {
      return (account) => {
        let formatData = {}
        const id = account.id
        const departmentId = account.departmentId
        const positionId = account.positionId
        const hireDate = account.hireDate
        const birthday = account.birthday
        const langs = account.langs
        formatData.id = !isset(id) ? '-' : ('000' + id).slice(-4)
        formatData.name = setValue(account.name)
        formatData.nameEn = setValue(account.nameEn)
        formatData.email = setValue(account.email)
        formatData.department = !isset(departmentId)
          ? '-'
          : convertMasterName(departmentId, 'departments')
              .replace('ICTソリューション-', '')
              .replace('ビジネスイノベーション', 'BI')
              .replace('インフラ事業部', 'インフラ')
        formatData.position = !isset(positionId)
          ? '-'
          : convertMasterName(positionId, 'positions')
        formatData.tel = setValue(account.tel)
        formatData.hireDate = !isset(hireDate)
          ? '-'
          : secondToTime(hireDate.seconds)
        formatData.birthday =
          !isset(birthday) || (isset(birthday) && birthday == '')
            ? '-'
            : secondToTime(birthday.seconds)
        if (formatData.birthday !== '-') {
          const dateOfBirth = dayjs(new Date(formatData.birthday))
          const today = dayjs(new Date())
          let baseAge = today.year() - dateOfBirth.year()

          const birthday = dayjs(
            new Date(
              today.year() +
                '/' +
                (dateOfBirth.month() + 1) +
                '/' +
                dateOfBirth.date(),
            ),
          )
          if (today.isBefore(birthday)) {
            formatData.age = baseAge - 1
          } else {
            formatData.age = baseAge
          }
        } else {
          formatData.age = null
        }
        if (!(Array.isArray(langs) && langs.length)) {
          formatData.langs = []
        } else {
          formatData.langs = []
          langs.forEach((val) => {
            formatData.langs.push(convertMasterName(val, 'langs'))
          })
        }
        formatData.hobby = setValue(account.hobby)
        formatData.free = setValue(account.free)
        return formatData
      }
    },
    isManager: () => {
      return (positionName) => {
        return !['チーフ', '一般社員'].includes(positionName)
      }
    },
    isGeneral: () => {
      return (positionName) => {
        return ['-', '一般社員'].includes(positionName)
      }
    },
  },
}
